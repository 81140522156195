<template>
  <v-text-field
    ref="field"
    v-model="model"
    v-bind="$attrs"
    :validate-on-blur="!validateOnInput"
    @blur="handleBlur"
    v-on="$listeners"
  >
    <template
      v-if="isComplete"
      v-slot:append
    >
      <v-icon color="success">
        mdi-check
      </v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    validateOnInput: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      model: '',
      isComplete: false,
      isDirty: false
    }
  },

  watch: {
    value (value) {
      this.model = value
      if (!this.isDirty) {
        this.$nextTick(this.updateIsComplete)
      }
    },

    model (value) {
      this.isDirty = true
      this.isComplete = false
      this.$emit('input', value)
      if (this.validateOnInput) {
        this.updateIsComplete()
      }
    }
  },

  mounted () {
    if (this.value) {
      this.model = this.value
      this.$nextTick(this.updateIsComplete)
    }
  },

  methods: {
    handleBlur () {
      this.updateIsComplete()
      this.$emit('blur')
    },

    updateIsComplete () {
      const { field } = this.$refs
      this.isComplete = this.model && field.validate()
    }
  }
}
</script>
